@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

/* Core CSS required for Ionic components to work properly */
@import '~@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/display.css';

// TailwindCSS utilities
@tailwind base;
@tailwind components;
@tailwind utilities;

html.md,
html.ios {
    --ion-default-font: 'Poppins', sans-sefif;
}

body {
    background-color: #fbfbfd;
    padding-top: var(--ion-safe-area-top);
}

ion-button {
    text-transform: none;
    &:not(.button-clear) {
        min-height: 2.75rem;
    }
}

.form-control {
    --padding-start: 0;
    &:not(.item-has-focus) ion-label {
        --color: #555;
    }
    &.is-invalid {
        --border-color: var(--ion-color-danger);
        ion-label {
            --color: var(--ion-color-danger);
        }
        ion-note {
            display: block;
        }
    }
}

.form-input {
    --color: #555;
    .native-input {
        padding-block: 0.5rem;
    }
    .label-text {
        font-weight: 600;
    }
    .select-label {
        color: var(--color);
        font-weight: 600;
    }
    &.input-label-placement-floating.sc-ion-input-md-h input.sc-ion-input-md,
    &.input-label-placement-floating.sc-ion-input-ios-h input.sc-ion-input-ios {
        opacity: 1;
    }
    &.show-required {
        .label-text::after,
        .select-label::after {
            content: ' *';
            color: var(--ion-color-danger);
        }
    }
    &.hide-helper .input-bottom .helper-text {
        display: none;
    }
    &.ion-invalid.ion-touched {
        --color: var(--ion-color-danger);
    }
}

ion-content.p-4 {
    --padding-start: 1rem;
    --padding-end: 1rem;
    --padding-top: 1rem;
    --padding-bottom: 1rem;
}

ion-tabs,
ion-modal {
    ion-content,
    ion-toolbar {
        --background: var(--ion-color-light);
    }
    ion-toolbar.ios {
        --min-height: 3.5rem;
    }
}

ion-tab-bar {
    --background: #fbfbfd;
    margin-bottom: calc(0.25rem + var(--ion-safe-area-bottom));
    &.ios {
        padding-block: 0.5rem;
    }
}

ion-button.has-badge::part(native) {
    overflow: visible;
}

.sc-ion-label-ios-s > p {
    color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.6);
}

ion-note {
    --color: var(--text-color);
    opacity: 0.8;
}

.bold-icon {
    --ionicon-stroke-width: 42px;
}

.datetime-modal {
    --height: 350px;
    --width: 80%;
    --border-radius: 0.25rem;
    --backdrop-opacity: 0.4 !important;
}

.time-modal {
    @extend .datetime-modal;
    --height: 250px;
}

.qrcode-modal {
    --diameter: 80vw;
    --height: var(--diameter);
    --width: var(--diameter);
    --border-radius: 0.25rem;
}

.custom-select-interface.select-alert {
    --width: 80%;
    --height: 60%;
    --max-width: 80%;
    font-size: 1rem;
    .alert-radio-group,
    .alert-checkbox-group {
        max-height: 100%;
        flex: 1 1 0%;
    }
}

.md {
    .datetime-modal.has-buttons {
        --height: 450px;
    }
    .prompt-alert .alert-button-role-confirm {
        font-weight: bold;
        border-bottom: 1px solid var(--ion-color-primary);
    }
}

@layer components {
    .card {
        @apply bg-white p-4 rounded shadow-lg;
    }
}
