// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

:root {
    --text-color: #000000;
    /** Ionic CSS Variables **/
    /** primary **/
    --ion-color-primary: #5d78ff;
    --ion-color-primary-rgb: 93, 120, 255;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255, 255, 255;
    --ion-color-primary-shade: #3758ff;
    --ion-color-primary-tint: #eef1ff;
    // --ion-color-primary-shade: #526ae0;
    // --ion-color-primary-tint: #6d86ff;

    /** secondary **/
    --ion-color-secondary: #eef1ff;
    --ion-color-secondary-rgb: 238, 241, 255;
    --ion-color-secondary-contrast: #000000;
    --ion-color-secondary-contrast-rgb: 0, 0, 0;
    --ion-color-secondary-shade: #d1d4e0;
    --ion-color-secondary-tint: #f0f2ff;

    /** tertiary **/
    --ion-color-tertiary: #f7d759;
    --ion-color-tertiary-rgb: 247, 215, 89;
    --ion-color-tertiary-contrast: #000000;
    --ion-color-tertiary-contrast-rgb: 0, 0, 0;
    --ion-color-tertiary-shade: #d9bd4e;
    --ion-color-tertiary-tint: #f8db6a;

    /** success **/
    --ion-color-success: #0abb87;
    --ion-color-success-rgb: 10, 187, 135;
    --ion-color-success-contrast: #000000;
    --ion-color-success-contrast-rgb: 0, 0, 0;
    --ion-color-success-shade: #09a577;
    --ion-color-success-tint: #23c293;

    /** warning **/
    --ion-color-warning: #ffb822;
    --ion-color-warning-rgb: 255, 184, 34;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0, 0, 0;
    --ion-color-warning-shade: #e0a21e;
    --ion-color-warning-tint: #ffbf38;

    /** danger **/
    --ion-color-danger: #ff0009;
    --ion-color-danger-rgb: 255, 0, 9;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #e00008;
    --ion-color-danger-tint: #ff1a22;

    /** dark **/
    --ion-color-dark: #262525;
    --ion-color-dark-rgb: 38, 37, 37;
    --ion-color-dark-contrast: #ffffff;
    --ion-color-dark-contrast-rgb: 255, 255, 255;
    --ion-color-dark-shade: #212121;
    --ion-color-dark-tint: #3c3b3b;

    /** medium **/
    --ion-color-medium: #94a3b8;
    --ion-color-medium-rgb: 148, 163, 184;
    --ion-color-medium-contrast: #000000;
    --ion-color-medium-contrast-rgb: 0, 0, 0;
    --ion-color-medium-shade: #828fa2;
    --ion-color-medium-tint: #9facbf;

    /** light **/
    --ion-color-light: #f2f3f8;
    --ion-color-light-rgb: 242, 243, 248;
    --ion-color-light-contrast: #000000;
    --ion-color-light-contrast-rgb: 0, 0, 0;
    --ion-color-light-shade: #d5d6da;
    --ion-color-light-tint: #f3f4f9;
}
